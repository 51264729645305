/* Swiperの矢印用 */
.swiper-button-next::after {
  color: #e91e63;
}
.swiper-button-prev::after {
  color: #e91e63;
}
/* Swiperのナビゲーション（ページを表す下部の丸）用 */
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #e91e63;
}
