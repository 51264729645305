* {
  font-family: "M PLUS 1p", sans-serif;
}

a {
  color: #e91e63;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #f8bbd0;
}

@media print {
  -webkit-print-color-adjust: exact;
  @page {
    size: 210mm 297mm;
    margin: 0;
    padding: 0;
  }
}
